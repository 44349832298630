import * as React from "react";
import HcpLayout from "../../components/hcp/layout";
import { Seo } from "../../components/seo";
import useDeviceSize from "../../hooks/use-device-size";

import { Col, Row } from "react-grid-system";
import AnchorLink from "react-anchor-link-smooth-scroll";
import bannerImg from "../../images/personal-stories/personal-stories-hero-bg-img.webp";
import bannerImgMobile from "../../images/personal-stories/personal-stories-hero-mob-bg-img.webp";
import KristenThumb from "../../images/icons/thumb-kristen-video.webp";
import BridgetteThumb from "../../images/icons/thumb-bridgette-video.webp";

import TaraThumb from "../../images/icons/thumb-tara-video.webp";
import DanaThumb from "../../images/icons/thumb-dana-video.webp";
import TatianaThumb from "../../images/icons/thumb-tatiana-video.webp";
import ElaineThumb from "../../images/icons/thumb-elaine-video.webp";
import LindseyThumb from "../../images/icons/thumb-lindsey-video.webp";
import CelinaThumb from "../../images/icons/thumb-celina-video.webp";
import ChrisThumb from "../../images/icons/thumb-chris-video.webp";
import bookIcon from "../../images/personal-stories/book-icon.svg";

import { TfiAngleRight } from "react-icons/tfi";
import { Link } from "gatsby";
import PersonalVideo from "../../components/cards/personalVideo";
import groupIcon from "../../images/icons/icons-support-bubbles.svg";
import bannerBlock from "../../images/personal-stories/cta-expectations.webp";
import bannerBlockMobile from "../../images/personal-stories/cta-expectations-mobile.webp";
import SocialIconBlock from "../../components/socialIconBlock";
import { StaticImage } from "gatsby-plugin-image";

import "./wakix-personal-stories.scss";
import InnerBanner from "../../components/InnerBanner";
// import StoriesSlider from "../../components/storiesSlider";

const WakixPersonalStories = () => {
    const deviceSizes = useDeviceSize();
    return (
        <HcpLayout pageClass="personal-stories-page">
            <InnerBanner
                topHeading="WAKIX Personal Stories"
                bannerClass="personal"
                bannerHeroImg={bannerImg}
                bannerHeroImgMobile={bannerImgMobile}
                bannerImgAlt="Tara, a real person with narcolepsy with cataplexy taking WAKIX, on a beach with friends and her dog"
                heroNameImg={
                    <StaticImage
                        src="../../images/hero-slides/tara-name-title.webp"
                        alt="Tara, a real person with narcolepsy with cataplexy taking WAKIX, on a beach with friends and her dog"
                        width={213}
                        height={102}
                        quality={95}
                        layout="constrained"
                        placeholder="none"
                        style={{ transition: "opacity 0.5s" }}
                        imgStyle={{ opacity: 1 }}
                    />
                }
            />

            <section className="inner-first page-info-text">
                <div className="centered-content">
                    <h1>Hear What Real People Have to Say About WAKIX</h1>
                    <h2>
                        Watch real people living with narcolepsy as they share
                        their journeys with the disorder and how WAKIX has
                        helped them
                    </h2>
                </div>
            </section>

            <section className="stories-rows-wrap">
                <div className="story-row">
                    <div className="centered-content">
                        {/*<StoriesSlider /> */}
                        <Row className="row align-items-center">
                            <Col lg={6} xl={5} order={{ xs: 2, lg: 1 }}>
                                <div className="text" id="personal-story-tara">
                                    <AnchorLink href="#personal-story-tara" />
                                    <h2>Meet Tara</h2>
                                    <h3>
                                        Healthcare worker, narcolepsy advocate,{" "}
                                        <span className="special">
                                            <br />
                                        </span>
                                        aunt, dog mom
                                    </h3>
                                    <p>
                                        After receiving her narcolepsy
                                        diagnosis, Tara felt both relieved and
                                        nervous. Hear how she got started on
                                        WAKIX and what she noticed about her
                                        excessive daytime sleepiness as time
                                        went on.
                                    </p>
                                </div>
                            </Col>

                            <Col
                                lg={6}
                                xl={7}
                                order={{ xs: 1, lg: 2 }}
                                className="two-column__video"
                            >
                                <div className="card-video-wrap">
                                    <PersonalVideo
                                        videoId="851702364"
                                        videoIdParam="415940d0e5"
                                        videoThumbnail={TaraThumb}
                                        altText={
                                            "Video thumbnail of Tara, a real person with narcolepsy with cataplexy taking WAKIX"
                                        }
                                        customTagText="Tara video"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

                {/*Story Row*/}
                <div className="story-row">
                    <div className="centered-content">
                        <Row className="row align-items-center">
                            <Col lg={6} xl={5} order={{ xs: 2, lg: 1 }}>
                                <div className="text" id="personal-story-dana">
                                    <AnchorLink href="#personal-story-dana" />
                                    <h2>Meet Dana</h2>
                                    <h3>
                                        Physician, illustrator, dream-chaser
                                    </h3>
                                    <p>
                                        When Dana learned about narcolepsy in
                                        medical school, she realized the
                                        symptoms she was experiencing were not
                                        normal. Hear why Dana’s sleep specialist
                                        recommended WAKIX and the changes she
                                        experienced in her excessive daytime
                                        sleepiness and cataplexy.
                                    </p>
                                </div>
                            </Col>

                            <Col
                                lg={6}
                                xl={7}
                                order={{ xs: 1, lg: 2 }}
                                className="two-column__video"
                            >
                                <div className="card-video-wrap">
                                    <PersonalVideo
                                        videoId="862522491"
                                        videoIdParam="42d09b65fc"
                                        videoThumbnail={DanaThumb}
                                        altText={
                                            "Video thumbnail of Dana, a real person with narcolepsy with cataplexy taking WAKIX"
                                        }
                                        customTagText="Dana video"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                {/*Story Row*/}

                <div className="story-row" id="meet-tatiana">
                    <div className="centered-content">
                        <Row className="row align-items-center">
                            <Col lg={6} xl={5} order={{ xs: 2, lg: 1 }}>
                                <div
                                    className="text"
                                    id="personal-story-tatiana"
                                >
                                    <AnchorLink href="#personal-story-tatiana" />
                                    <h2>Meet Tatiana</h2>
                                    <h3>Writer, painter, nature lover</h3>
                                    <p>
                                        When Tatiana was in college, she started
                                        falling asleep in class. Hear how she
                                        learned about WAKIX and what she noticed
                                        about her excessive daytime sleepiness
                                        after starting WAKIX.
                                    </p>
                                </div>
                            </Col>

                            <Col
                                lg={6}
                                xl={7}
                                order={{ xs: 1, lg: 2 }}
                                className="two-column__video"
                            >
                                <div className="card-video-wrap">
                                    <PersonalVideo
                                        videoId="802768190"
                                        videoIdParam="5eac4d2e03"
                                        videoThumbnail={TatianaThumb}
                                        altText={
                                            "Video thumbnail of Tatiana, a real person with narcolepsy with cataplexy taking WAKIX"
                                        }
                                        customTagText="Tatiana video"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                {/*Story Row*/}
                <div className="story-row" id="meet-elaine">
                    <div className="centered-content">
                        <Row className="row align-items-center">
                            <Col lg={6} xl={5} order={{ xs: 2, lg: 1 }}>
                                <div
                                    className="text"
                                    id="personal-story-elaine"
                                >
                                    <AnchorLink href="#personal-story-elaine" />
                                    <h2>Meet Elaine</h2>
                                    <h3>Hockey fan, extrovert, dog mom</h3>
                                    <p>
                                        After struggling with her excessive
                                        daytime sleepiness at work, Elaine made
                                        the tough decision to quit a job she
                                        loved. When she heard about WAKIX at her
                                        narcolepsy support group, she asked her
                                        doctor about trying it. Hear Elaine
                                        share her story of starting WAKIX and
                                        how her loved ones noticed a difference
                                        in her symptoms.
                                    </p>
                                </div>
                            </Col>

                            <Col
                                lg={6}
                                xl={7}
                                order={{ xs: 1, lg: 2 }}
                                className="two-column__video"
                            >
                                <div className="card-video-wrap">
                                    <PersonalVideo
                                        videoId="802764958"
                                        videoIdParam="2dbf66a44d"
                                        videoThumbnail={ElaineThumb}
                                        altText={
                                            "Video thumbnail of Elaine, a real person with narcolepsy with cataplexy taking WAKIX"
                                        }
                                        customTagText="Elaine video"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                {/*Story Row */}
                <div className="story-row" id="meet-chris">
                    <div className="centered-content">
                        <Row className="row align-items-center">
                            <Col lg={6} xl={5} order={{ xs: 2, lg: 1 }}>
                                <div className="text" id="personal-story-chris">
                                    <AnchorLink href="#personal-story-chris" />
                                    <h2>Meet Chris</h2>
                                    <h3>
                                        Mental health counselor, weight lifter,
                                        explorer
                                    </h3>
                                    <p>
                                        After assessing Chris’s excessive
                                        daytime sleepiness and cataplexy, his
                                        healthcare provider suggested that it
                                        might be time for a change. After they
                                        worked together to find the right dose
                                        of WAKIX, Chris’s family and friends
                                        noticed a difference. Hear how Chris
                                        found a plan that worked for him.
                                    </p>
                                </div>
                            </Col>

                            <Col
                                lg={6}
                                xl={7}
                                order={{ xs: 1, lg: 2 }}
                                className="two-column__video"
                            >
                                <div className="card-video-wrap">
                                    <PersonalVideo
                                        videoId="718472305"
                                        videoIdParam="c9b5d7c301"
                                        videoThumbnail={ChrisThumb}
                                        altText={
                                            "Video thumbnail of Chris, a real person with narcolepsy with cataplexy taking WAKIX"
                                        }
                                        customTagText="Chris video"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                {/*Story Row*/}
                <div className="story-row" id="meet-kristen">
                    <div className="centered-content">
                        <Row className="row align-items-center">
                            <Col lg={6} xl={5} order={{ xs: 2, lg: 1 }}>
                                <div
                                    className="text"
                                    id="personal-story-kristen"
                                >
                                    <AnchorLink href="#personal-story-kristen" />
                                    <h2>Meet Kristen</h2>
                                    <h3>
                                        Nonprofit fundraiser, traveler, music
                                        lover
                                    </h3>
                                    <p>
                                        After years of struggling with excessive
                                        daytime sleepiness, Kristen continued to
                                        have conversations with her healthcare
                                        providers about what she was feeling.
                                        Hear Kristen share what got her
                                        interested in WAKIX and the importance
                                        of open communication with her
                                        healthcare provider.
                                    </p>
                                </div>
                            </Col>

                            <Col
                                lg={6}
                                xl={7}
                                order={{ xs: 1, lg: 2 }}
                                className="two-column__video"
                            >
                                <div className="card-video-wrap">
                                    <PersonalVideo
                                        videoId="530414633"
                                        videoIdParam="c0bdb08df8"
                                        videoThumbnail={KristenThumb}
                                        altText={
                                            "Video thumbnail of Kristen, a real person with narcolepsy taking WAKIX"
                                        }
                                        customTagText="Kristen video"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                {/*Story Row*/}
                <div className="story-row" id="meet-bridgette">
                    <div className="centered-content">
                        <Row className="row align-items-center">
                            <Col lg={6} xl={5} order={{ xs: 2, lg: 1 }}>
                                <div
                                    className="text"
                                    id="personal-story-bridgette"
                                >
                                    <AnchorLink href="#personal-story-bridgette" />
                                    <h2>Meet Bridgette</h2>
                                    <h3>
                                        Navy veteran, philosophy enthusiast,
                                        advocate
                                    </h3>
                                    <p>
                                        When Bridgette started falling asleep at
                                        work, she knew something needed to
                                        change. Hear about her journey to a
                                        diagnosis and how WAKIX has helped.
                                    </p>
                                </div>
                            </Col>

                            <Col
                                lg={6}
                                xl={7}
                                order={{ xs: 1, lg: 2 }}
                                className="two-column__video"
                            >
                                <div className="card-video-wrap">
                                    <PersonalVideo
                                        videoId="534721787"
                                        videoIdParam="8b3f715dec"
                                        videoThumbnail={BridgetteThumb}
                                        altText={
                                            "Video thumbnail of Bridgette, a real person with narcolepsy with cataplexy taking WAKIX"
                                        }
                                        customTagText="Bridgette video"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                {/*Story Row*/}
                <div className="story-row" id="meet-lindsey">
                    <div className="centered-content">
                        <Row className="row align-items-center">
                            <Col lg={6} xl={5} order={{ xs: 2, lg: 1 }}>
                                <div
                                    className="text"
                                    id="personal-story-lindsey"
                                >
                                    <AnchorLink href="#personal-story-lindsey" />
                                    <h2>Meet Lindsey</h2>
                                    <h3>Baker, gardener, dreamer</h3>
                                    <p>
                                        After learning about WAKIX through
                                        narcolepsy support groups, Lindsey asked
                                        her doctor for more information about
                                        how WAKIX might help improve her
                                        excessive daytime sleepiness. This is
                                        her story of how she found the
                                        wakefulness she was looking for.
                                    </p>
                                </div>
                            </Col>

                            <Col
                                lg={6}
                                xl={7}
                                order={{ xs: 1, lg: 2 }}
                                className="two-column__video"
                            >
                                <div className="card-video-wrap">
                                    <PersonalVideo
                                        videoId="530418881"
                                        videoIdParam="27bbc77f66"
                                        videoThumbnail={LindseyThumb}
                                        altText={
                                            "Video thumbnail of Lindsey, a real person with narcolepsy with cataplexy taking WAKIX"
                                        }
                                        customTagText="Lindsey video"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                {/*Story Row*/}
                <div className="story-row" id="meet-celina">
                    <div className="centered-content">
                        <Row className="row align-items-center">
                            <Col lg={6} xl={5} order={{ xs: 2, lg: 1 }}>
                                <div
                                    className="text"
                                    id="personal-story-celina"
                                >
                                    <AnchorLink href="#personal-story-celina" />
                                    <h2>Meet Celina</h2>
                                    <h3>
                                        Healthcare worker, graduate student, dog
                                        mom
                                    </h3>
                                    <p>
                                        When Celina found herself fighting to
                                        keep her eyes open during the day, she
                                        knew it was time to seek help. Hear
                                        about Celina’s experience starting WAKIX
                                        and how she realized WAKIX was working
                                        for her.
                                    </p>
                                </div>
                            </Col>

                            <Col
                                lg={6}
                                xl={7}
                                order={{ xs: 1, lg: 2 }}
                                className="two-column__video"
                            >
                                <div className="card-video-wrap">
                                    <PersonalVideo
                                        videoId="591098899"
                                        videoIdParam="aad5a1976c"
                                        videoThumbnail={CelinaThumb}
                                        altText={
                                            "Video thumbnail of Celina, a real person with narcolepsy taking WAKIX"
                                        }
                                        customTagText="Celina video"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                {/*Story Row*/}
            </section>

            <section className="block-hear-your-story story-matters-us text-center bg-blue">
                <div className="centered-content">
                    <div className="image-and-text">
                        <img
                            src={bookIcon}
                            alt="Telling your narcolepsy story journal icon"
                            className="image"
                        />
                        <h2>We want to hear your story</h2>
                    </div>
                    <div className="block-content">
                        <p>
                            Your story matters. Sharing it can help educate and
                            inspire others living with narcolepsy. We're looking
                            for people like you who want to share their
                            narcolepsy journey and experience with WAKIX.{" "}
                        </p>
                        <p>
                            If you are 18 years or older and want to learn more
                            about the opportunity to share your story, we would
                            like to hear from you!
                        </p>
                        <p className="sub-title">
                            <a href="tel:8559254978" className="no-uline">
                                Please call 855-WAKIXSTORY{" "}
                                <nobr
                                    style={{
                                        font: "inherit",
                                        color: "inherit",
                                    }}
                                >
                                    (855-925-4978).
                                </nobr>
                            </a>
                        </p>
                    </div>
                </div>
            </section>

            <section className="banner-block two-columns no-pb no-pt">
                <div className="col bg-img">
                    <div className="inner bg-cover">
                        {deviceSizes?.mdDown ? (
                            <img
                                src={bannerBlockMobile}
                                alt="Chris discussing WAKIX and symptoms with his healthcare provider"
                            />
                        ) : (
                            <img
                                src={bannerBlock}
                                alt="Chris discussing WAKIX and symptoms with his healthcare provider"
                            />
                        )}
                        <div className="content">
                            <h3 className="txt-shadow-white">
                                Access resources to <br />
                                start a conversation <br />
                                with your healthcare <br />
                                provider
                            </h3>
                        </div>
                        <div className="btn-wrap">
                            <Link
                                to="/resources-and-support/downloads"
                                className="btn btn-primary btn-primary--md"
                            >
                                Go now
                                <TfiAngleRight />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="col bg-green">
                    <div className="inner">
                        <div className="img-rounded-wrap">
                            <div className="img-rounded">
                                <img
                                    src={groupIcon}
                                    alt="Connecting with others in the narcolepsy community"
                                />
                            </div>
                        </div>
                        <div className="text">
                            <h3>
                                Connect with others in the narcolepsy community{" "}
                            </h3>
                            <div className="btn-wrap">
                                <Link
                                    to="/resources-and-support/support-organizations"
                                    className="btn btn-primary btn-primary--md"
                                >
                                    Find support groups
                                    <TfiAngleRight />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SocialIconBlock />
        </HcpLayout>
    );
};

export default WakixPersonalStories;

export const Head = () => (
    <Seo
        title="WAKIX® (pitolisant) Personal Stories"
        description="Watch the stories of real people taking WAKIX and hear their journey of living with narcolepsy."
        keywords="patient experience with WAKIX, patient experience with pitolisant, personal stories of WAKIX, personal stories of pitolisant, WAKIX patient stories"
    />
);
